import { PREFIX_API } from 'constants/api.const';
import qs from 'query-string';
import { map } from 'lodash';
import {
  CameraStateModel,
  CloseCameraSessionRequestModel,
  ListCameraResponseModel,
  ListCapturePositionResponseModel,
  ListRatioResponseModel,
  OpenCameraSessionRequestModel,
  PicturePhotoModel,
  RecordVideoRequestModel,
  RecordVideoResponseModel,
  StartRecordVideoRequestModel,
  TakePhotoRequestModel,
  TakePhotoResponseModel,
} from 'models/photo/camera.model';
import { getCamDeviceApi } from 'helpers/url.helper';
import { baseSliceApi } from './base.slice-api';

export const camerasApi = baseSliceApi.injectEndpoints({
  endpoints: (build) => ({
    listCameraApiAction: build.query<CameraStateModel['listCameras'], void>({
      query: () => ({
        url: `${PREFIX_API.API}/${getCamDeviceApi()}/getlist`,
      }),
      providesTags: (data) => [
        ...map(data?.data, ({ ID }) => ({ type: 'Camera', ID } as const)),
        { type: 'Camera' as const, id: 'LIST' },
      ],
      extraOptions: { maxRetries: 1 },
      transformResponse: (rawResult: ListCameraResponseModel, meta) => ({
        data: rawResult?.response?.data ?? [],
        totalRecord: rawResult?.response?.totalRecord ?? 0,
      }),
    }),
    listCapturePositionApiAction: build.query<
      CameraStateModel['listCapturePositions'],
      void
    >({
      query: () => ({
        url: `${PREFIX_API.API}/booth/getlistcaptureposition`,
      }),
      providesTags: (res) => [
        ...map(
          res?.data,
          ({ id }) => ({ type: 'CapturePosition', id } as const),
        ),
        { type: 'CapturePosition' as const, id: 'LIST' },
      ],
      extraOptions: { maxRetries: 1 },
      transformResponse: (rawResult: ListCapturePositionResponseModel) => ({
        data: rawResult?.response?.data ?? [],
        totalRecord: rawResult?.response?.totalRecord ?? 0,
      }),
    }),
    openCameraApiAction: build.mutation<boolean, OpenCameraSessionRequestModel>(
      {
        query: (payload) => ({
          url: `${
            PREFIX_API.API
          }/${getCamDeviceApi()}/opensession?${qs.stringify(payload)}`,
        }),
        extraOptions: { maxRetries: 0 },
        invalidatesTags: () => [{ type: 'Camera', id: 'LIST' }],
      },
    ),
    closeCameraApiAction: build.mutation<
      boolean,
      CloseCameraSessionRequestModel
    >({
      query: () => ({
        url: `${PREFIX_API.API}/${getCamDeviceApi()}/closesession`,
      }),
      extraOptions: { maxRetries: 0 },
      invalidatesTags: () => [{ type: 'Camera', id: 'LIST' }],
    }),
    startCameraLiveViewApiAction: build.mutation<boolean, void>({
      query: () => ({
        url: `${PREFIX_API.API}/${getCamDeviceApi()}/startliveview`,
      }),
      extraOptions: { maxRetries: 0 },
      invalidatesTags: () => [{ type: 'Camera', id: 'LIST' }],
    }),
    stopCameraLiveViewApiAction: build.mutation<boolean, void>({
      query: () => ({
        url: `${PREFIX_API.API}/${getCamDeviceApi()}/stopliveview`,
      }),
      extraOptions: { maxRetries: 0 },
      invalidatesTags: () => [{ type: 'Camera', id: 'LIST' }],
    }),
    backStopCameraLiveViewApiAction: build.mutation<boolean, void>({
      query: () => ({
        url: `${PREFIX_API.API}/${getCamDeviceApi()}/backstopliveview`,
      }),
      extraOptions: { maxRetries: 0 },
      invalidatesTags: () => [{ type: 'Camera', id: 'LIST' }],
    }),
    listRatioApiAction: build.query<CameraStateModel['listRatios'], void>({
      query: () => ({
        url: `${PREFIX_API.API}/${getCamDeviceApi()}/getlistratio`,
      }),
      providesTags: (data) => [
        ...map(data?.data, ({ id }) => ({ type: 'Ratio', id } as const)),
        { type: 'Ratio' as const, id: 'LIST' },
      ],
      extraOptions: { maxRetries: 1 },
      transformResponse: (rawResult: ListRatioResponseModel, meta) => ({
        data: rawResult?.response?.data ?? [],
        totalRecord: rawResult?.response?.totalRecord ?? 0,
      }),
    }),
    takePhotoApiAction: build.mutation<
      PicturePhotoModel | null,
      TakePhotoRequestModel
    >({
      query: (payload) => ({
        url: `${PREFIX_API.API}/${getCamDeviceApi()}/takephoto/${
          payload?.transactionid
        }/${payload?.filename || ''}`,
      }),
      extraOptions: { maxRetries: 0 },
      transformResponse: (rawResult: TakePhotoResponseModel) =>
        rawResult?.response ?? null,
      invalidatesTags: () => [{ type: 'PhotoTaken', id: 'LIST' }],
    }),
    takePhotoOriginApiAction: build.mutation<
      PicturePhotoModel | null,
      TakePhotoRequestModel
    >({
      query: (payload) => ({
        url: `${PREFIX_API.API}/${getCamDeviceApi()}/takephotoorigin/${
          payload?.transactionid
        }/${payload?.filename || ''}`,
      }),
      extraOptions: { maxRetries: 0 },
      transformResponse: (rawResult: TakePhotoResponseModel) =>
        rawResult?.response ?? null,
      invalidatesTags: () => [{ type: 'PhotoTaken', id: 'LIST' }],
    }),
    startRecordVideoApiAction: build.mutation<
      boolean,
      StartRecordVideoRequestModel
    >({
      query: () => ({
        url: `${PREFIX_API.API}/${getCamDeviceApi()}/startrecordvideo`,
      }),
      extraOptions: { maxRetries: 0 },
    }),
    recordVideoApiAction: build.mutation<
      PicturePhotoModel | null,
      RecordVideoRequestModel
    >({
      query: (payload) => ({
        url: `${PREFIX_API.API}/${getCamDeviceApi()}/recordvideo/${
          payload?.transactionid
        }/${payload?.filename || ''}`,
      }),
      transformResponse: (rawResult: RecordVideoResponseModel) =>
        rawResult?.response?.data ?? null,
      extraOptions: { maxRetries: 0 },
      invalidatesTags: () => [{ type: 'PhotoTaken', id: 'LIST' }],
    }),
    recordVideoOriginApiAction: build.mutation<
      PicturePhotoModel | null,
      RecordVideoRequestModel
    >({
      query: (payload) => ({
        url: `${PREFIX_API.API}/${getCamDeviceApi()}/recordvideoorigin/${
          payload?.transactionid
        }/${payload?.filename || ''}`,
      }),
      transformResponse: (rawResult: RecordVideoResponseModel) =>
        rawResult?.response?.data ?? null,
      extraOptions: { maxRetries: 0 },
      invalidatesTags: () => [{ type: 'PhotoTaken', id: 'LIST' }],
    }),
    generateVideoApiAction: build.mutation<boolean, void>({
      query: () => ({
        url: `${PREFIX_API.API}/${getCamDeviceApi()}/generatevideo`,
      }),
      extraOptions: { maxRetries: 0 },
    }),
  }),
});

export const {
  useListCameraApiActionQuery,
  useListCapturePositionApiActionQuery,
  useOpenCameraApiActionMutation,
  useCloseCameraApiActionMutation,
  useStartCameraLiveViewApiActionMutation,
  useStopCameraLiveViewApiActionMutation,
  useBackStopCameraLiveViewApiActionMutation,
  useListRatioApiActionQuery,
  useTakePhotoApiActionMutation,
  useTakePhotoOriginApiActionMutation,
  useRecordVideoApiActionMutation,
  useRecordVideoOriginApiActionMutation,
  useStartRecordVideoApiActionMutation,
  useGenerateVideoApiActionMutation,
} = camerasApi;

export const {
  endpoints: {
    listCameraApiAction,
    listCapturePositionApiAction,
    takePhotoApiAction,
    takePhotoOriginApiAction,
    listRatioApiAction,
    recordVideoApiAction,
    recordVideoOriginApiAction,
    openCameraApiAction,
  },
} = camerasApi;
